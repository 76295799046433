@import "../../../screen.scss";

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

section.pageContainer {
    min-height: fit-content;
    padding-top: var(--padding-500);
    padding-bottom: var(--padding-500);
}

.problemWrapper {

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.problemContainer {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: calc(-1 * var(--border-radius-400));
        left: calc(-1 * var(--border-radius-400));
        height: calc(100% + var(--border-radius-400) * 2);
        width: calc(100% + var(--border-radius-400) * 2);

        background: var(--color-accent-background-gradient);
        border-radius: calc(2 * var(--border-radius-400));
        z-index: -1;
        animation: animatedgradient 20s ease alternate infinite;
        background-size: 300% 300%;
    }
}

.problem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: var(--padding-800);

    border-radius: var(--border-radius-450);
    background: var(--color-background-start);

    height: 100%;
}

.content {
    max-width: var(--width-600);

    p {
        color: var(--color-text-400);
        font-size: var(--font-size-600);
        line-height: var(--line-height-600);
        margin-bottom: var(--margin-200);
    }
}

@media (prefers-reduced-motion) {
    .problem::before {
        animation: none;
    }
}

@media (max-width: $screen-laptop) {
    section.pageContainer {
        padding-top: var(--padding-1000);
        padding-bottom: var(--padding-500);
    }
}

@media (max-width: $screen-tablet) {

    .problemContainer,
    .problemWrapper,
    .problem {
        width: 100%;
    }

    .problem {
        padding: var(--padding-700) calc(var(--width-gap) * 0.5);

    }

    .pageContainer {
        padding: calc(var(--width-gap) * 0.5);
    }
}


@media (max-width: $screen-mobile) {

    $border-width: var(--margin-300);

    .problem {
        max-width: calc(100vw - $border-width * 2);
        padding-left: calc(var(--width-gap) * 0.8);
        padding-right: calc(var(--width-gap) * 0.8);
    }

    section.pageContainer {
        padding-left: 0;
        padding-right: 0;
        padding-top: var(--padding-800);
        padding-bottom: var(--padding-800);
    }

    .problemContainer {
        &::before {
            border-radius: 0;

            top: calc(-1 * $border-width);
            left: calc(-1 * $border-width);
            height: calc(100% + $border-width * 2);
            width: calc(100% + $border-width * 2);
        }
    }

    .content {
        p {
            font-size: var(--font-size-500);
            line-height: var(--line-height-500);
        }
    }

}
