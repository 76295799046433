@import "../../screen.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
}

.address {
  margin-left: var(--margin-50);
  font-weight: var(--font-bold);

  @media (max-width: $screen-small-mobile) {
    display: none;
  }
}
