@import "../../screen.scss";

@mixin flare-effect-on-hover {
  &:after {
    background: #fff;
    content: "";
    height: 200%;
    left: -60%;
    opacity: 0.2;
    position: absolute;
    top: -50%;
    transform: rotate(35deg);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
  }

  &:hover:after {
    left: 120%;
  }

  @media (max-width: $screen-tablet) {
    &:hover:after {
      left: 150%;
    }
  }
}

.button {
  --button-height: 2.5rem;
  --button-hor-padding: var(--padding-100);
  --button-min-width: min-content;

  color: var(--color-text-350);

  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;

  width: auto;
  min-width: var(--button-min-width);
  background: transparent;
  background-color: var(--color-primary-750);

  border: none;
  pointer-events: auto;
  padding: 0px;
  user-select: none;
  cursor: pointer;

  padding-left: var(--button-hor-padding);
  padding-right: var(--button-hor-padding);

  height: var(--button-height);
  line-height: var(--button-height);

  transition: background 0.25s ease 0s, color 0.25s ease 0s,
    border-color 0.25s ease 0s, box-shadow 0.25s ease 0s,
    transform 0.25s ease 0s, opacity 0.25s ease 0s,
    outline-offset 0.25s ease 0s;

  border-width: var(--border-width-400);

  &:hover {
    background-color: var(--color-primary-750);
  }

  &:active {
    transform: scale(0.97);
  }

  &.dark {
    box-shadow: 0 1px 2px 0 var(--color-dark-700);
    color: var(--color-text-200);
    background-color: var(--color-dark-700);

  }

  &:not(.bordered) {

    @include flare-effect-on-hover;

    &:hover {
      transform: translateY(calc(-1*var(--padding-10)));
      box-shadow: 0px 10px 15px -10px rgba(121, 50, 212, 0.397);
    }
  }
}

.icon {
  svg:last-of-type {
    margin-left: calc(var(--padding-250) / 2);
  }
}

.iconLeft {
  svg:first-of-type {
    margin-left: 0;
    margin-right: calc(var(--padding-250) / 2);
  }
}

.sm {
  --button-height: 2rem;
  --button-hor-padding: var(--padding-200);

  border-radius: var(--border-radius-450);
  font-size: var(--font-size-200);
  &:not(.bordered) {
    &:after {
      left: -100%;
    }
  }
}

.md {
  --button-hor-padding: var(--padding-350);

  border-radius: var(--border-radius-450);
  font-size: var(--font-size-300);

  &.wide {
    --button-min-width: 200px;
    --button-hor-padding: var(--padding-300);
  }
}

.lg {
  --button-height: 3rem;
  --button-hor-padding: var(--padding-400);

  border-radius: var(--border-radius-500);
  font-size: var(--font-size-500);

  &.wide {
    --button-min-width: 200px;
    --button-hor-padding: var(--padding-300);
  }
}

.slightlyRound {
  border-radius: var(--border-radius-600);
}

.round {
  border-radius: 9999px;
}

.bordered {
  border-color: var(--color-primary-500);
  color: var(--color-text-200);
  background-color: transparent;
  border-style: solid;

  &:hover {
    background-color: var(--color-primary-700);
    border-color: var(--color-primary-700);
  }

  &.dark {
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    color: var(--color-text-300);
    background-color: transparent;
    border-color: var(--color-text-400);
    border: 0 solid;

    outline: 1px solid;
    outline-color: var(--color-text-400);
    outline-offset: 0px;

    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1), box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
      box-shadow: inset 0 0 20px rgba(190, 190, 190, 0.5), 0 0 20px rgba(255, 255, 255, .2);
      outline-color: rgba(255, 255, 255, 0);

      border: 1px solid;
      border-color: var(--color-text-300);
      outline-offset: 15px;
    }
  }
}

.gradient {
  background: linear-gradient(
    112deg,     
    hsl(308, 27%, 35%) 0%, 
    hsl(232, 69%, 37%) 100%) !important; // override global.scss that can get higher specificity
  color: var(--color-text-200);
}

.lightGradient {
  background: linear-gradient(
    112deg,  
    hsl(239 84% 67%) 50%, 
    hsl(265, 61%, 66%) 100%) !important; // override global.scss that can get higher specificity
  color: var(--color-text-200);
}

.disabled {
  background: var(--color-neutral-600) !important;
  color: var(--color-text-200) !important;
  pointer-events: none;

  &.bordered {
    background: none !important;
    border-color: var(--color-neutral-600) !important;
    color: var(--color-text-100) !important;
  }

  &.lightGradient {
    background-color: var(--color-dark-400) !important;
  }
}

.inDevelopment {
  background: repeating-linear-gradient(
    145deg,
    var(--color-neutral-600),
    var(--color-neutral-600) 20px,
    rgba(39, 39, 39, 0.15) 20px,
    rgba(39, 39, 39, 0.15) 40px
  ) !important;
}
