@import "../../../screen.scss";


.solutionParalax {
  z-index: -100;
}

.backgroundTextBox {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  justify-content: flex-end;


}

.backgroundText {
  font-size: var(--font-size-1300);
  font-weight: var(--font-black);
  letter-spacing: var(--letter-spacing-black-800);
  color: var(--color-text-background-500);
  text-transform: uppercase;
  text-align: right;
}



@media (max-width: $screen-desktop) {
  .backgroundText {
    font-size: var(--font-size-1250);
    letter-spacing: var(--letter-spacing-black-500);
  }
}

@media (max-width: $screen-desktop) {
  .backgroundText {
    font-size: var(--font-size-1240);
    letter-spacing: var(--letter-spacing-black-500);
  }
}


@media (max-width: $screen-tablet) {
  .backgroundText {
    font-size: var(--font-size-1230);
    letter-spacing: var(--letter-spacing-black-500);
  }
}

@media (max-width: $screen-mobile) {
  .backgroundText {
    font-size: var(--font-size-1180);
    letter-spacing: var(--letter-spacing-black-500);
  }
}

@media (max-width: $screen-small-mobile) {
  .backgroundText {
    font-size: var(--font-size-1150);
    letter-spacing: var(--letter-spacing-black-500);
  }
}