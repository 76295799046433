@import "../../../screen.scss";

section.pageContainer {
    min-height: fit-content;
    padding-top: var(--padding-500);
    padding-bottom: var(--padding-1000);
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.revolution {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    position: relative;
    width: 100%;

    max-width: var(--width-600);

    z-index: 2;
}

.imageContainer {
    position: relative;
    margin-left: var(--margin-600);
    z-index: 1;

    &:before {
        --blur-size: 1rem;

        content: "";
        position: absolute;
        top: var(--blur-size);
        left: var(--blur-size);
        height: calc(100% - 2 * var(--blur-size));
        width: calc(100% - 2 * var(--blur-size));

        background: linear-gradient(to bottom, 
            #190491,
            #024e7a, 
            #0616a7,
            #020e7a,  
            #880C86, 
            #e20494, 
            #13DDD4,  
            #FE7B3A,
            #310aa8,
            #21017a
        );
        border-radius: calc(2 * var(--border-radius-400));
        z-index: -1;
        mix-blend-mode: screen;
        filter: blur(3rem);
    }

    img {
        height: auto;
        width: 40vw;

        max-width: var(--width-600);
        box-shadow: 0px 16px 31px -10px rgb(5, 6, 17);

        transform: rotate3d(0, 1, 0, -3deg);
        
        
    }
}

.child {
    & > p {
        color: var(--color-text-400);
        font-size: var(--font-size-600);
        line-height: var(--line-height-600);
        margin-bottom: var(--margin-200);
    }

    & > p, & > a {
        max-width: var(--width-600);
    }
}

@media (max-width: $screen-laptop) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .revolution {
        max-width: var(--width-600);
    }

    .imageContainer {
        margin-left: 0;
        margin-top: var(--margin-700);

        img {
            width: 50vw;
        }
    }
}

@media (max-width: $screen-tablet) {
    .imageContainer {

        img {
            width: 60vw;
        }
    }
}

@media (max-width: $screen-mobile) {

    section.pageContainer {
        padding-bottom: 0;
    }
    .child {
        p {
            font-size: var(--font-size-500);
            line-height: var(--line-height-500);
        }
    }

    .imageContainer {
        img {
            width: 70vw;
        }
    }
}


