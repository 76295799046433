@import "../screen.scss";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  width: 100%;
  height: 100%;

  max-width: var(--max-width);
  padding: 0 var(--width-gap);
  padding-bottom: var(--padding-500);

}