.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    // (!) Fallback for browsers that don't support CSS relative color functions
    // This "linear-gradient" function is a part of "postcss-easing-gradients" plugin,
    // so color should be static and inlined (no CSS variables allowed).
    // We need this plugin to stretch the single color to the whole smooth gradient.
    background: linear-gradient(in oklch to bottom, #f79533, ease-in-out, transparent);

    @supports (color: hsl(from white h s l)) {
      background: linear-gradient(
        180deg,
        var(--color-token) 0%,
        rgb(from var(--color-token) r g b / 50%) 50%,
        rgb(from var(--color-token) r g b / 12%) 75%,
        transparent 87%
      );
    }

    z-index: 100;
  }
}

.imageWrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-15%);
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 1);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0) 100%);
}
