@import "../../../screen.scss";
@import "../../../components/styles/gradient-text.mixin.scss";

section.pageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.solutionContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media (prefers-reduced-motion) {
  .solutionContainer::before {
    animation: none;
  }
}

.solution {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--padding-700);
  border-radius: var(--border-radius-450);
  padding-left: var(--padding-500);
}

.content {
  max-width: var(--width-600);

  p {
    color: var(--color-text-400);
    font-size: var(--font-size-600);
    line-height: var(--line-height-600);
    margin-bottom: var(--margin-200);

    a {
      text-decoration: underline;

      &:hover {
        color: var(--color-text-300);
      }
    }
  }
}

@media (max-width: $screen-laptop) {
  section.pageContainer {
    flex-direction: column-reverse;
  }

  .solution {
    padding-left: var(--padding-700);
  }
}


@media (max-width: $screen-tablet) {
  .solutionContainer,
  .solution {
    width: 100%;
  }

  .solution {
    padding: var(--padding-700) calc(var(--width-gap) * 0.5);
  }

  section.pageContainer {
    padding: calc(var(--width-gap) * 0.5);
  }
}


@media (max-width: $screen-mobile) {
  section.pageContainer {
    padding: 0;
    padding-bottom: var(--padding-800);
  }

  .solution {
    padding: var(--padding-700) calc(var(--width-gap) * 0.8);
  }

  .content {
    p {
      font-size: var(--font-size-500);
      line-height: var(--line-height-500);
    }
  }
}

@media (max-width: $screen-small-mobile) {
  .pageContainer {
    padding: 0;
  }
}
